













































import { Comanda, ComandaForm, Loja } from '@/models';
import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import { ComandaUseCase, FindLojaUseCase } from '@/usecases';
import { Vue, Component, Watch } from 'vue-property-decorator'
import CardDaComandas from '@/components/loja/CardDeComandas.vue';

@Component({
	components: {
		CardDaComandas,
	},
})
export default class TelaDeComandas extends Vue {

	comandasSelecionadas: ComandaForm[] | null = null
	comandasDaLoja: Comanda[] = []
	buscaPorNomeOuIdenticador = ''
	loja: Loja | null = null


	findLojaUseCase = new FindLojaUseCase
	comandaUseCase = new ComandaUseCase

	get idLoja() {
		return this.$route.query.l as string
	}

	get comandasOrdenados() {
		const comandas = this.loja && this.loja.comandas && this.comandasSelecionadas === null
			? [...this.loja.comandas].sort((comandaA, comandaB) =>
				comandaA.nome.localeCompare(comandaB.nome),
			)
			: this.comandasSelecionadas != null && this.comandasSelecionadas.length > 0
				? [...this.comandasSelecionadas].sort((comandaA, comandaB) =>
					comandaA.nome.localeCompare(comandaB.nome),
				)
				: []


		return comandas
	}

	@Watch('buscaPorNomeOuIdenticador', { immediate: true })
	async bucarComanda() {
		if(!this.loja) return 
		if(!this.buscaPorNomeOuIdenticador){ 
			this.comandasSelecionadas = null
			return 
		}
		try {
			const comanda = await this.comandaUseCase.getComandas(this.buscaPorNomeOuIdenticador, this.loja.id)
			this.comandasSelecionadas = comanda.content
		} catch(error) {
			AlertModule.setError(error)
		}
	}

	@Watch("idLoja")
	async buscarLoja(lojaId: string) {
		if(!lojaId) return
		try {
			const loja = await this.findLojaUseCase.findLojaById(lojaId)
			this.loja = loja
		} catch(error) {
			AlertModule.setError(error)
		}
	}

	reloadComandas() {
		if(!this.loja) return
		this.buscarLoja(this.loja.id)
	}

}
