










































import type { ItemDaComanda } from '@/models';
import { Component, PropSync, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class DialogoDeObservacao extends Vue {
	@PropSync('mostra', { default: false }) mostrarObservacao!: boolean
	@PropSync('itemComanda', { type: Object }) itemDaComanda!: ItemDaComanda
	
	observacao = ''


	@Watch('mostrarObservacao')
	inserirObservacao(){
		this.observacao = this.itemDaComanda.observacao
	}

	setObservacao() {
		this.itemDaComanda.observacao = this.observacao
		this.observacao = ''
		this.$emit('input', this.itemDaComanda)
		this.$emit('mostra')
	}

	cancelar() {
		this.observacao = ''
		this.$emit('mostra')
	}
}

