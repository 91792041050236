import pdfMake from 'pdfmake/build/pdfmake'
import type { ContentText, TDocumentDefinitions } from 'pdfmake/interfaces'
import vfs from 'pdfmake/build/vfs_fonts.js'
import print from 'print-js'
import { Comanda, ItemDaComanda } from '@/models'
import { formatarMoeda } from '../formatadores'
import { dateTimeToPtBrFormat } from '../date/date-utils'
import Decimal from 'decimal.js'

window.pdfMake.vfs = vfs.pdfMake.vfs

const SETE = 7
const CENTER = 'center'


async function gerarDocumentoParaImpressao(comanda: Comanda): Promise<string> {
	const docDefinition: TDocumentDefinitions = {
		pageSize: {
			width: 230,
			height: 500,
		},
		pageOrientation: 'portrait',
		pageMargins: [ 3, 5, 5, 5 ],
		content: [
			{ text: `${comanda.nome || ''}\n`, fontSize: 20, bold: true, alignment: CENTER },
			{ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 225, y2: 0, lineWidth: 0.2 }], fontSize: SETE },
			buildTabelaDosItensDaComanda(comanda.itemDaComanda || [], [
				{ text: 'descricaoDoProduto', style: 'tableColumns' },
				{ text: 'preco', style: 'tableColumns' },
				{ text: 'quantidade', style: 'tableColumns' },
				{ text: 'observacao', style: 'tableColumns', alignment: 'right' },
				{ text: 'valorTotalPorItem', style: 'tableColumns' },
			]),
			{ text: ` TOTAL: ${formatarMoeda(comanda.itemDaComanda.map(item => item.preco * item.quantidade).reduce((valor, total) => valor + total, 0)) || ''}\n`, fontSize: 10, bold: true, alignment: CENTER },
			{ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 225, y2: 0, lineWidth: 0.2 }], fontSize: SETE },
			{ text: `Emitido em ${dateTimeToPtBrFormat(new Date() || '')}\n`, style: 'labels' },
			{ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 225, y2: 0, lineWidth: 0.2 }], fontSize: SETE },
		],
		styles: {
			labels: {
				fontSize: SETE,
				alignment: CENTER,
			},
			tableColumns: {
				bold: true,
				fontSize: SETE,
			},
		},
	}

	return new Promise(
		resolve => pdfMake.createPdf(docDefinition).getBase64((result) => resolve(result)),
	)
}

export async function imprimirCupomDaComanda(comanda: Comanda): Promise<void> {
	const cupomDaComanda: string = await gerarDocumentoParaImpressao(comanda)
	return new Promise((resolve, reject) => {
		print({
			printable: cupomDaComanda,
			type: 'pdf',
			base64: true,
			onError: reject,
			onLoadingEnd: resolve,
		})
	})
}

function buildTabelaDosItensDaComanda(itensDaVenda: ItemDaComanda[], colunas: ContentText[]) {
	return {
		table: {
			headerRows: 1,
			widths: [ 43, 20, 10, 60, 40 ],
			body: buildCorpoDaTabelaDosItensDaComanda(itensDaVenda, colunas),
		},
		layout: {
			hLineWidth: () => 0,
			vLineWidth: () => 0,
		},
	}
}

function buildCorpoDaTabelaDosItensDaComanda(itensDaComanda: ItemDaComanda[], colunas: ContentText[]) {
	const body: ContentText[][] = []
	const colunasDaTabelaDeItens: ContentText[] = [
		{ text: 'Descrição', style: 'tableColumns' },
		{ text: 'V. Unit.', style: 'tableColumns', alignment: 'center' },
		{ text: 'Qtd.', style: 'tableColumns' },
		{ text: 'Observação', style: 'tableColumns' },
		{ text: 'V. Total', style: 'tableColumns', alignment: 'left' },
	]
	body.push(colunasDaTabelaDeItens)
	itensDaComanda.forEach(itemDaComanda => {
		const dataRow: ContentText[] = []
		colunas.forEach(coluna => {
			let conteudoDaLinha = coluna.text === 'preco'
				? `${formatarMoeda(Number(itemDaComanda[coluna.text]))}`
				: itemDaComanda[coluna.text as string]
			if (coluna.text === 'descricaoDoProduto') {
				conteudoDaLinha = itemDaComanda.produto.nome
			}
			if (coluna.text === 'observacao') {
				conteudoDaLinha = itemDaComanda.observacao
			}
			if (coluna.text === 'valorTotalPorItem') {
				conteudoDaLinha = `${valorTotalPorItem(itemDaComanda)}`
			}
			dataRow.push({
				text: conteudoDaLinha,
				bold: false,
				fontSize: SETE,
			})
		})
		body.push(dataRow)
	})
	return body
}

function valorTotalPorItem(itemDaVenda: ItemDaComanda) {
	return formatarMoeda(new Decimal(itemDaVenda.preco).mul(itemDaVenda.quantidade).toNumber())
}