




































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class ConfirmExclusionDialog extends Vue {
	@Prop({ type: String, default: 'Deseja remover o item?' }) title!: string
	@Prop({ type: String }) message!: string
	show: boolean | null = null
}

