
















import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import BuscaDeProdutoDropdown from './BuscaDeProdutoDropdown.vue'
import BuscaDeProdutoPorCodBarras from './BuscaDeProdutoPorCodBarras.vue'
import BuscaDeProdutoPorCodBarrasEntrada from './BuscaDeProdutoPorCodBarrasEntrada.vue'
import BuscaDeProdutoPorCodBarrasRomaneio from './BuscaDeProdutoPorCodBarrasRomaneio.vue'

@Component({
	components: {
		BuscaDeProdutoDropdown,
		BuscaDeProdutoPorCodBarras,
		BuscaDeProdutoPorCodBarrasRomaneio,
		BuscaDeProdutoPorCodBarrasEntrada,
	},
})
export default class BuscaDeProduto extends Vue {
	@Ref() campo!: BuscaDeProdutoDropdown | BuscaDeProdutoPorCodBarras
	@Prop({ type: Boolean, default: false }) buscaUnica!: boolean
	@Prop({ type: Boolean, default: false }) buscaPorRomaneio!: boolean
	@Prop({ type: Boolean, default: false }) buscaParaEntradas!: boolean

	get componenteUtilizado() {
		if(this.buscaPorRomaneio) return 'BuscaDeProdutoPorCodBarrasRomaneio'
		if(this.buscaParaEntradas) return 'BuscaDeProdutoPorCodBarrasEntrada'
		
		return this.buscaUnica
			? 'BuscaDeProdutoPorCodBarras'
			: 'BuscaDeProdutoDropdown'
	}

	focus() {
		this.campo.focus()
	}

	blur() {
		this.campo.blur()
	}
}
