








































































































































































































































































import SeletorDeProdutos from '@/components/produto/SeletorDeProdutos.vue'
import Confirmacao from '@/components/ui/Confirmacao.vue'
import type { Loja } from '@/models'
import type { ComandaForm } from '@/models/Comanda'
import { ItemDaComanda } from '@/models/Comanda'
import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils'
import { maiorOuIgualAZero, obrigatorio } from '@/shareds/regras-de-form'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import AplicarTabelaPrecoNaComandaUseCase from '@/usecases/tabela-de-precos/AplicarTabelaPrecoNaComandaUseCase'
import { Component, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator'
import DialogoDeObservacao from './DialogoDeObservacao.vue'
import StatusDaComanda from '@/components/loja/StatusDaComanda.vue'
import { converterProdutoParaProdutoSimples, obterQuantidadePesadaNaBalanca } from '@/shareds/produto-shareds'
import { FindProdutoUseCase } from '@/usecases/produto/FindProdutoUseCase'
import { FindConfiguracaoDaContaUseCase } from '@/usecases'
import VueQuagga from "vue-quaggajs"

Vue.use(VueQuagga);


@Component({
	components: {
		StatusDaComanda,
		SeletorDeProdutos,
		DialogoDeObservacao,
		Confirmacao,
	},
})
export default class DialogoDeComandas extends Vue {
	@Ref() form!: HTMLFormElement
	@Ref() confirmarComanda!: Confirmacao
	@Prop({ type: Object, default: criarComanda }) initialValue!: ComandaForm
	@Prop({ type: Boolean, default: false }) disabled!: boolean
	@Prop({ type: Boolean, default: false }) readonly!: boolean
	@PropSync('loja', { type: Object }) lojaSync?: Loja

	comanda: ComandaForm = criarComanda()

	mostra = false
	mostrarDialogoSelecaoProduto = false
	mostrarDialogoObservacao = false
	mostrarDialogoLeituraDeCodigoBarras = false
	showCamera = false
	buscandoProduto = false
	valorLido: string | null = null

	findProdutoUseCase = new FindProdutoUseCase()
	findConfiguracaoDaContaUseCase = new FindConfiguracaoDaContaUseCase()

	readerSize = {
		width: 860,
		height: 480,
	}

	itemDaComanda = criarItemComanda()

	obrigatorio = obrigatorio
	maiorOuIgualAZero = maiorOuIgualAZero
	dateTimeToPtBrFormat = dateTimeToPtBrFormat

	@Watch('mostra')
	onChangeMostra(mostra: boolean) {
		if (!mostra) return
		this.comanda = JSON.parse(JSON.stringify(this.initialValue))
	}

	concluirComanda() {
		if(!this.comanda.aberto && this.comanda.itemDaComanda.length > 0) return AlertModule.setError('Para confirmar é necessario abrir a comanda')
		if (!this.form.validate()) return
		this.$emit('updateComanda', this.comanda)
		this.mostra = false
	}

		
	setItemComanda(item: ItemDaComanda) {
		this.itemDaComanda = item
		this.confirmarComanda.mostrar()
	}

	async atualizarListaDeProdutos(item: ItemDaComanda) {
		if(!this.lojaSync) return AlertModule.setError("Selecione uma loja")
		if(this.comanda.itemDaComanda === null) this.comanda.itemDaComanda = []
		try{
			const itemDaComanda = await AplicarTabelaPrecoNaComandaUseCase({
				loja: this.lojaSync,
				item,
			})
			const idx = this.comanda.itemDaComanda.map(item => item.produto.id).indexOf(itemDaComanda.produto.id)
			if(idx === -1) {
				this.comanda.itemDaComanda.push(itemDaComanda)
			} else {
				const itemFormatado = this.comanda.itemDaComanda[idx]
				itemFormatado.quantidade += 1
				this.comanda.itemDaComanda.splice(idx, 1, itemFormatado)
			}
			this.mostrarDialogoSelecaoProduto = false
		} catch(error) {
			AlertModule.setError(error)
			this.mostrarDialogoSelecaoProduto = false
		}

	}

	beep() {
		const audio = document.querySelector('audio')
		if(!audio) return 
		audio.play()
	}

	async logIt(data: any) {
		this.showCamera = false
		this.buscandoProduto = true
		this.beep()
		this.valorLido = data.codeResult.code

		if (this.valorLido && this.lojaSync) {
			try {
				const produto = await this.findProdutoUseCase.findProdutoComEstoque(
					this.valorLido,
					this.lojaSync.id,
				)

				const configuracaoDeConta = await this.findConfiguracaoDaContaUseCase.find()

				const quantidadeDeItens =
					produto.unidadeDeMedida !== 'KG' && produto.unidadeDeMedida != 'MT'
						? 1
						: obterQuantidadePesadaNaBalanca(
							'',
							configuracaoDeConta?.medida || 6,
							configuracaoDeConta?.eans || 7,
							configuracaoDeConta?.casasNumericas || 4,
						)
				const item: ItemDaComanda = {
					id: '',
					produto: converterProdutoParaProdutoSimples(produto),
					quantidade: quantidadeDeItens,
					desconto: { isPercentual: true, valor: 0.0 },
					preco: produto.preco || 0,
					precoDeCusto: 0,
					idConjunto: null,
					observacao: '',
				}

				this.atualizarListaDeProdutos(item)
			} catch (error: any) {
				AlertModule.setError(error)
			} finally {
				this.buscandoProduto = false
				this.showCamera = true
			}
		}
	}

}

function criarComanda(): ComandaForm {
	return {
		id: '',
		identificador: '',
		dataHoraDeAbertura: '',
		aberto: false,
		nome: '',
		descricao: '',
		itemDaComanda: [],
		createdAt: '',
		updatedAt: '',
		taxaDeServico: 0,
		aguardandoProcessamentoNoCaixa: false,
	}
}


function criarItemComanda(): ItemDaComanda {
	return {
		produto: {	
			id: '',
			nome: '',
			tipo: 'Padrão',
			preco: 0,
			pesoLiquido: 0,
			pesoBruto: 0,
			descontoVarejo: 0,
			eans: [],
			sku: null,
			identificadores:[],
			nomeCompleto: '',
			produtoDesativado: false,
			isValePresente: false,
			urlImagens: null,
			skuPai: null,
		},
		id: '',
		preco: 0,
		precoDeCusto: 0,
		quantidade: 0,
		desconto: {
			isPercentual: false,
			valor: 0,
		},
		idConjunto: null,
		observacao: '',
	}
}

