import { FiltroDeBuscaDeProdutos } from '@/models'
import { Pageable } from '@/models/Pageable'
import { AxiosRequestConfig } from 'axios'
import { container } from 'inversify-props'
import { ProdutoServiceAdapter } from './adapter'

type UseCaseParams = {
	service?: ProdutoServiceAdapter
	filtros?: FiltroDeBuscaDeProdutos
	pageable?: Pageable
	axiosConfig?: AxiosRequestConfig
}

export default async({
	service = container.get<ProdutoServiceAdapter>('ProdutoServiceAdapter'),
	filtros,
	pageable,
	axiosConfig,
}) => {
	return await service.findByFiltro(filtros, pageable, axiosConfig)
}
