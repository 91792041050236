











import type { Comanda } from "@/models/Comanda";
import { Vue, Prop, Component } from "vue-property-decorator";

@Component({})
export default class StatusDaComanda extends Vue {

	@Prop() comanda!: Comanda
	@Prop({ default: false }) mostrarDescricao?: boolean

	get descricaoStatus() {
		return `${ this.comanda.aberto ? 'Aberto' : 'Fechado' }`
	}
}

