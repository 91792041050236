





























































































import { Vue, Component, Watch, Ref } from 'vue-property-decorator'
import { Produto, ProdutoComVariantes, AtributoDoProduto, AtributosDaVariante, Variante } from '@/models'
import { FindProdutoUseCase } from '@/usecases'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'

@Component
export default class DialogoDeSelecionarVariante extends Vue {
	@Ref() cards!: {
		$el: HTMLElement
	}[]
	@Ref() revisar!: HTMLLinkElement

	produto: ProdutoComVariantes | null = null
	mostra = false
	indiceAtributoAtual = 0
	valoresSelecionados: string[] = []
	findProdutoUseCase = new FindProdutoUseCase()
	atributosSelecionados: AtributosDaVariante[] = []
	variante: Variante | null = null
	buscando = false
	temVariante = true

	async abrir(produto: Promise<DialogoDeSelecionarVariante['produto']>) {
		this.produto = null
		this.valoresSelecionados = []
		this.atributosSelecionados = []
		this.mostra = true
		this.produto = await produto
	}

	fechar() {
		this.mostra = false
	}

	get atributo(): AtributoDoProduto | null {
		return (
			this.produto?.atributosProduto[this.valoresSelecionados.length] ?? null
		)
	}

	@Watch('atributo')
	onChangeAtributo() {
		setTimeout(() => {
			if (
				this.valoresSelecionados.length ===
					this.produto?.atributosProduto.length &&
				!this.selecionado
			) {
				this.focarRevisar()
			} else {
				this.focarPrimeiroCard()
			}
		})
	}

	get items() {
		return (
			this.produto?.atributosProduto.map((atributo, indice) => {
				const valor: string | undefined = this.valoresSelecionados[indice]

				return {
					id: atributo.id,
					disabled: indice > this.valoresSelecionados.length,
					text: valor
						? `${atributo.atributo.nome}: ${this.valoresSelecionados[indice]}`
						: `${atributo.atributo.nome}`,
				}
			}) ?? []
		)
	}

	obterIndiceDoAtributo(atributo: AtributoDoProduto) {
		return (
			this.produto?.atributosProduto.findIndex(
				({ id }) => atributo.id === id,
			) ?? -1
		)
	}

	resetarPara(atributo: AtributoDoProduto) {
		const indice = this.obterIndiceDoAtributo(atributo)
		if (indice <= -1) return
		this.valoresSelecionados.splice(indice)
		this.atributosSelecionados.splice(indice)
	}

	get selecionado () {
		const produto = this.produto
		if (
			!produto ||
			this.valoresSelecionados.length !== produto.atributosProduto.length
		)
			return null

		return this.variante
	}

	async selecionarValor(valor: string, nome: string) {
		this.valoresSelecionados.push(valor)
		this.atributosSelecionados.push({
			id: null,
			nome: nome,
			valor: valor,
			descontoVarejo: 0,
		})

		if (this.valoresSelecionados.length ===
					this.produto?.atributosProduto.length) {
			try {
				this.buscando = true
				const variante = await this.findProdutoUseCase.buscarVariantePorAtributos(this.produto.id, this.atributosSelecionados)
				await this.findProdutoUseCase.validarProdutoDesativado(variante.id)
				this.variante = variante
				if (!this.variante) this.temVariante = false
			} catch (error: any) {
				AlertModule.setError(error)
				this.mostra = false
			} finally {
				this.buscando = false
			}
		}
	}

	@Watch('mostra')
	onChangeMostra(mostra: boolean) {
		if (!mostra) {
			this.$emit('cancel')
		}
	}

	@Watch('selecionado')
	onChangeSelecionado(produtoSelecionado: Produto | null) {
		if (!produtoSelecionado) return

		this.$emit('input', produtoSelecionado)
		this.mostra = false
		this.variante = null
	}

	focarPrimeiroCard() {
		if (!this.cards[0]) return
		this.cards[0].$el.focus()
	}

	focarRevisar() {
		if (!this.revisar) return
		this.revisar.focus()
	}
}
